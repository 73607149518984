import {  Box, Card, CssVarsProvider, Divider, Typography } from "@mui/joy";
import TopMenu from "./components/TopMenu";
import Ads from "./components/Ads";
import theme from "./themes/theme";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { ConfigProvider, Spin, message } from "antd";
import VoteModal from "./components/Vote";
import { useEffect, useState } from "react";
import BuySells from "./components/BuySells";
import Footer from './components/Footer';
import useWindowDimensions from './modules/useWindowDimensions'
import { useParams } from 'react-router';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AdBanner from "./components/AdBanner";
import Disclaimer from "./components/Disclaimer";
import { Avatar, Button, Chip, Code, Link, Snippet, Spinner } from "@nextui-org/react";
import { DoneAllRounded } from "@mui/icons-material";
import { Analytics } from '@vercel/analytics/react';

const Chains = [
    {
      name: 'ETH',
      icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1704569810/eth_nfk0da.svg'
    },
    {
      name: 'BSC',
      icon: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=026'
    },
    {
      name: 'ARB',
      icon: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=026'
    },
    {
      name: 'SOL',
      icon: 'https://cryptologos.cc/logos/solana-sol-logo.svg?v=029'
    },
    {
      name: 'APT',
      icon: 'https://cryptologos.cc/logos/aptos-apt-logo.svg?v=029'
    },
    {
        name: 'OP',
        icon: 'https://cryptologos.cc/logos/optimism-ethereum-op-logo.svg?v=029'
    },
    {
        name: 'AVAX',
        icon: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=029'
    }
]

const Platforms = [
    {
      name: 'CMC',
      icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084286/cmc_mnw1kn.png'
    },
    {
      name: 'CG',
      icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084285/cg_xgrfxz.png'
    },
    {
      name: 'CMC_CG',
      icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084283/cmc-cg_aov988.png'
    }
  ]

function Icon({src, href}) {
    return <Link href={href}>
        <Button isIconOnly color="danger" className="bg-[#feb200]" size='sm' radius="full">
            <img src={src} style={{width: `${30}px`, padding: '6px', borderRadius: '6px'}} />
        </Button>
    </Link>
}

function Coins() {

    const params = useParams()

    const [messageApi, contextHolder] = message.useMessage();
    const [open, setOpen] = useState(false);
    const [currentTokenForVote, setCurrentTokenForVote] = useState();
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    const [adData, setAdData] = useState({})
    const [adLoading, setAdLoading] = useState(true)

    const [failed, setFailed] = useState(false)

    useEffect(() => {
        fetch('https://bixiscope-api.vercel.app/api/get_ads').then(r => r.json()).then(r => {
          setAdData(r)
          setAdLoading(false)
        })
    }, [])
    

    useEffect(() => {
        fetch('https://bixiscope-api.vercel.app/api/get_token?token=' + params.token).then(r => r.json()).then(r => {
            setData(r)
            setLoading(false)
        }).catch(e => {
            setFailed(true)
            setTimeout(() => {
                window.open('/', "_self")
            }, 1000);
        })
    }, [])

    const token = params.token


    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    useEffect(() => {
        setWidth(windowWidth);
        setHeight(windowHeight);
    }, [windowWidth, windowHeight]);

    if (failed) {
        return <div class='flex justify-center items-center w-full h-full absolute bg-black'>
            <Code color='default'>Wrong Configuration. Retrying in 1 second.</Code>
        </div>
    }

    if (loading) {
        return <div class='flex justify-center items-center w-full h-full absolute bg-black'>
            <Spinner color='white' />
        </div>
    }

    return <div class='flex flex-col items-center'>
        {/* <Disclaimer /> */}
        <CssVarsProvider theme={theme}>
            <ConfigProvider theme={{
                components: {
                    Message: {
                        zIndexPopup: 9999
                    }
                }
            }}>
                {contextHolder}
            </ConfigProvider>
            <VoteModal open={open} setOpen={setOpen} currentTokenForVote={data.token} messageApi={messageApi} />
            <TopMenu />
            <div class='container flex items-center flex-col'>
                <div class='px-4'>
                    <Ads data={adData} setData={setAdData} adLoading={adLoading} />
                </div>
                <div class='px-4 flex gap-4 flex-col w-full'>
                    <div class='flex gap-4 flex-col' style={width < 870 ? { width: '100%' } : {}}>
                        <Card sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', background: '#101113', border: 'none' }} style={width < 768 ? { flexDirection: 'column', alignItems: 'center', border: '2px #21232e solid' } : {border: '2px #21232e solid'}}>
                            <div class={'flex flex-wrap justify-center ' + (width > 768 ? 'flex-col' : '')}>
                                <Avatar src={data.icon} size='lg' radius="full" classname='bg-transparent' style={width < 768 ? {height: '60px', width: '60px'} : { height: '80px', width: '80px' }} />
                                <div class={'ml-2 gap-2 flex flex-col flex-wrap '+(width < 768 ? 'items-center' : '')}>
                                    <div class={'flex gap-2 items-center flex-wrap '+(width < 768 ? 'justify-center' : '')}>
                                        <Typography  className='!text-white' level='h4' style={{textAlign: width < 768 ? 'center' : 'left'}}>{data.baseToken.name}</Typography>
                                        <Chip size='md' color='danger' className="bg-[#feb200] text-black" style={{fontWeight: 600, color: 'white', borderRadius: '6px'}}>${data.baseToken.symbol}</Chip>
                                        {
                                            data.platform ? 
                                        <Avatar radius="full" src={Platforms.filter(r => data.platform == r.name)[0].icon} />
                                        : ''}
                                        {
                                            data.kyc ? 
                                            <Chip size='md' color='success' startContent={<DoneAllRounded sx={{color: 'white'}} />} style={{fontWeight: 600, color: 'white', borderRadius: '6px'}}><Typography className='!text-white'  fontWeight={700} sx={{color: 'white'}}>KYC</Typography></Chip>
                                        : ''}
                                        {
                                            data.audit ? 
                                            <Chip size='md' color='success' startContent={<DoneAllRounded sx={{color: 'white'}} />} style={{fontWeight: 600, color: 'white', borderRadius: '6px'}}><Typography className='!text-white'  fontWeight={700} sx={{color: 'white'}}>Audit</Typography></Chip>
                                        : ''}
                                    </div>
                                    <Typography  className='!text-white' level='body-sm' style={{opacity: 0.7, textAlign: width < 768 ?  'center' : 'left'}}>{data.description}</Typography>
                                    <div class='flex gap-2 items-center'>
                                        {/* <Chip style={{background: '#fcb900', borderRadius: '6px', padding: '4px', paddingInline: '8px'}}
                                        startDecorator={<img src={Chains.filter(r => r.name == data.chain)[0].icon} class='h-6 mr-2' />}
                                        endDecorator={<ContentCopyRoundedIcon sx={{ width: '30px',color: 'white' }} />}
                                        >
                                            {data.token.slice(0, 4)}...{data.token.slice(-4)}
                                        </Chip> */}
                                        <Snippet codeString={data.token} symbol={<img src={Chains.filter(r => r.name == data.chain)[0].icon} class='h-6' />} classNames={{pre: 'flex items-center gap-2'}} variant="flat" size="sm" color='default'>{data.token.slice(0, 4)+'....'+data.token.slice(-4)}</Snippet>
                                    </div>
                                    <div class='flex justify-start gap-2'>
                                        <Icon src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644484/telegram_zzxn1u.svg' href={data.telegram}/>
                                        <Icon src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644701/twitter-white_n2t2hj.svg' href={data.twitter}/>
                                        <Icon src='https://res.cloudinary.com/dvwrpep92/image/upload/v1696160718/scan_qnd1zl.svg' href={data.scan}/>
                                        <Icon src='https://res.cloudinary.com/dvwrpep92/image/upload/v1696443793/website_yefvnh.svg' href={data.web}/>
                                    </div>
                                </div>
                            </div>
                            <div class='flex flex-col gap-2 items-end' style={width < 768 ? { alignItems: 'center' } : {}}>
                                <Typography className='!text-white'  level='body-lg' sx={{opacity: 0.5, whiteSpace: width < 768 ? '' : 'nowrap', textAlign: width < 768 ? 'center' : 'auto'}}>{data.baseToken.name} Price (USD):</Typography>
                                <div class='flex'>
                                    <Typography className='!text-white'  level='h4' sx={{whiteSpace: 'nowrap'}}>$ {data.priceUsd}</Typography>
                                    <Typography className='!text-white'  fontWeight={600} style={{color: data.priceChange.h24 < 0 ? 'rgb(216, 65, 46)' : 'rgb(0, 171, 112)'}} startDecorator={(data.priceChange.h24 > 0) ? <ArrowDropUpIcon style={{ color: 'rgb(0, 171, 112)', marginRight: '-5px', marginLeft: '5px' }} /> : <ArrowDropDownIcon style={{ color: 'rgb(216, 65, 46)', marginRight: '-5px', marginLeft: '5px' }} />}>{data.priceChange.h24}%</Typography>
                                </div>
                                {/* startContent={<img src="https://res.cloudinary.com/dvwrpep92/image/upload/v1696161230/bunny-mono_imh2xi.png" style={{height: '22px'}} />} */}
                                <Button size='md' color='primary' onClick={() => window.open(data.swap)}><Typography fontWeight={600} sx={{color: 'white'}}>Trade</Typography></Button>
                            </div>
                        </Card>
                    </div>
                    {/* <Card sx={{background: '#101113', border: 'none'}}>
                        <Typography level='title-lg'>Description</Typography>
                        <Typography level='body-sm' style={{opacity: 0.7}}>{data.description}</Typography>
                    </Card> */}
                    <div class={'flex gap-4 '+(width < 1024 ? 'flex-col-reverse' : '')}>
                        <Card style={{border: '2px #21232e solid'}} sx={{background: '#101113', border: 'none', flexGrow: 0.5}}>
                            <Typography className='!text-white'  level='title-lg'>Market Data</Typography>
                            <div class='flex justify-between' style={width < 768 ? {flexDirection: 'column', gap: '20px'} : {}}>
                                <div style={width < 768 ? {display: 'flex', justifyContent: 'space-between'} : {}}>
                                    <Typography className='!text-white' level='body-md' style={{opacity: 0.5}}>Price</Typography>
                                    <Typography className='!text-white'  level='title-lg'>$ {data.priceUsd.toLocaleString('en-US')}</Typography>
                                </div>
                                <div style={width < 768 ? {display: 'flex', justifyContent: 'space-between'} : {}}>
                                    <Typography className='!text-white'  level='body-md' style={{opacity: 0.5}}>Price Change (24h)</Typography>
                                    <Typography className='!text-white'  level='title-lg' style={{color: data.priceChange.h24 < 0 ? 'rgb(216, 65, 46)' : 'rgb(0, 171, 112)'}} startDecorator={(data.priceChange.h24 > 0) ? <ArrowDropUpIcon style={{ color: 'rgb(0, 171, 112)', marginRight: '-5px' }} /> : <ArrowDropDownIcon style={{ color: 'rgb(216, 65, 46)', marginRight: '-5px', marginLeft: '5px' }} />}>{data.priceChange.h24}%</Typography>
                                </div>
                                <div style={width < 768 ? {display: 'flex', justifyContent: 'space-between'} : {}}>
                                    <Typography className='!text-white'  level='body-md' style={{opacity: 0.5}}>Marketcap</Typography>
                                    <Typography className='!text-white'  level='title-lg'>$ {data.fdv.toLocaleString('en-US')}</Typography>
                                </div>
                                <div style={width < 768 ? {display: 'flex', justifyContent: 'space-between'} : {}}>
                                    <Typography className='!text-white'  level='body-md' style={{opacity: 0.5}}>Total Liquidity</Typography>
                                    <Typography className='!text-white'  level='title-lg'>$ {data.liquidity.usd.toLocaleString('en-US')}</Typography>
                                </div>
                            </div>
                        </Card>
                        <Card style={{border: '2px #21232e solid'}} sx={{ display: 'flex', background: '#101113', justifyContent: 'space-between', flexDirection: 'row', border: 'none', padding: 1, paddingInline: 2, flexGrow: 0.5, alignItems: 'center' }}>
                                <div class='flex flex-col items-center'>
                                    <Typography  className='!text-white' sx={{ opacity: 0.4 }} level='title-xs'>Total Votes</Typography>
                                    <Typography className='!text-white'  level='title-lg'>{data.votes}</Typography>
                                </div>
                                <Button color="danger" className="bg-[#feb200] text-black" size='lg' radius='sm' isDisabled={localStorage.getItem(data.token) ? true : false} onClick={() => { setOpen(true); messageApi.info('Complete the captcha to vote.', 2); setCurrentTokenForVote(data.token) }}>Vote for {data.baseToken.symbol}</Button>
                        </Card>
                    </div>
                    <iframe class='w-full h-[800px] rounded-[10px]' style={{border: '2px #21232e solid'}} src={data.url + '?embed=1&info=0&theme=dark'} />
                    {/* <Card style={width < 870 ? { width: '100%' } : { width: '40%' }}>
                        <Typography level='title-lg'>{data.baseToken.name}</Typography>
                        <Typography level='body-sm'>{data.description}</Typography>
                        <Card sx={{ display: 'flex', background: '#38445c', justifyContent: 'space-between', width: '100%', flexDirection: 'row', border: '0px', padding: 1, paddingInline: 2 }}>
                            <div>
                                <Typography sx={{ opacity: 0.4 }} level='title-xs'>Votes</Typography>
                                <Typography level='title-lg'>{data.votes}</Typography>
                            </div>
                            <Button color="success" disabled={localStorage.getItem(data.token) ? true : false} onClick={() => { setOpen(true); messageApi.info('Complete the captcha to vote.', 2); setCurrentTokenForVote(data.token) }}>Vote</Button>
                        </Card>
                        <BuySells data={data} />
                    </Card> */}
                </div>
                <div class='px-4 mt-10'>
                    <Ads data={adData} setData={setAdData} adLoading={adLoading} />
                </div>
            </div>
        </CssVarsProvider>
        <Footer />
        <Analytics />
    </div>
}

export default Coins;