import { AutoComplete, Autocomplete, AutocompleteOption, Button, Grid, Image, Input, ListItemContent, ListItemDecorator } from "@mui/joy";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import useWindowDimensions from "../modules/useWindowDimensions";

function Ads({data, setData, adLoading, index}) {

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    useEffect(() => {
        setWidth(windowWidth);
        setHeight(windowHeight);
    }, [windowWidth, windowHeight]);


    function filterImagesByScreenWidth(images) {
      return images.filter(r => r.size >= width).reverse()[0] || images[0]
    }

    // function ResizableImage({srcs}) {
    //   console.log(filterImagesByScreenWidth(srcs), width)
    //   return <img style={{borderRadius: '10px'}} src={filterImagesByScreenWidth(srcs).url} />
    // }

    function ResizableImage({srcs}) {
        let filter = filterImagesByScreenWidth(srcs.image)
        console.log(filter)
        let url = srcs.url
        if (filter.url == '') {
          filter = data.default.image.filter(r => r.res == filter.res)[0]
          url = data.default.url
          console.log(data.default)
        }
        return <img style={{borderRadius: '10px'}} class='hover:cursor-pointer' src={filter.url} onClick={() => window.open(url)} />
      }

    if (adLoading) {
      return <div></div>
    }


    if (adLoading) {
      return <div></div>
    }

    return (
        <div class='w-full flex flex-col gap-4 items-center mb-10'>
            <div class='flex justify-between items-center flex-wrap gap-2' style={width < 1536 ? {flexDirection: 'column', gap: '10px'} : {}}>
                <ResizableImage srcs={data.small[0+(index||0)]} />
                <ResizableImage srcs={data.small[1+(index||0)]} />
            </div>
            <Typography sx={{color: '#feb200' || '#ffba00', ":hover": {textDecoration: 'underline', cursor: 'pointer'}}} onClick={() => window.open('https://t.me/WENWIFHATCHAT')}>Your banner here? Contact us!</Typography>
            {/* <div>
              <ResizableImage srcs={data.premium[0]} />
            </div> */}
        </div>
    );
}

export default Ads;