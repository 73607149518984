import { Typography } from "@mui/joy";
import useWindowDimensions from "../modules/useWindowDimensions";
import { useEffect, useState } from "react";
import { Button, Divider, Link } from "@nextui-org/react";

function Footer() {

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  useEffect(() => {
      setWidth(windowWidth);
      setHeight(windowHeight);
  }, [windowWidth, windowHeight]);


    function CustomLink({href, text}) {
      return <Link sx={{color: 'white'}} color='neutral' href={href} style={width < 800 ? {
        background: '#fcb900',
        padding: '4px',
        display: 'inline',
        textAlign: 'center',
        borderRadius: '10px'
      } : {}}>{text}</Link>
    }

    return <div class='bg-[#141b27] bg-[black] border-t-[#21232e] border-t-[1.5px] flex items-center flex-col py-6 gap-4 w-full mt-10'>
      <div class='flex justify-center w-full container items-center px-2 gap-4' style={width < 800 ? {flexDirection: 'column', alignItems: 'center'} : {}}>
      <Link href='/'><div className="flex gap-3 items-center"><img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1717171261/18e774ff-aadf-4741-8b8c-d71cc7cb8c6c.png' className="h-10 hover:cursor-pointer hover:opacity-75 transition-opacity rounded-full" /><p className='text-2xl text-white'>WenScope</p></div></Link>
        {/* <div class='flex gap-4' style={width < 800 ? {flexDirection: 'column', width: '100%'} : {}}>
          <CustomLink color='neutral' href='/' text="All Time Rankings" />
          <CustomLink color='neutral' href='/' text="Daily Rankings" />
          <CustomLink color='neutral' href='/' text="New Listings" />
          <CustomLink color='neutral' href='https://docs.google.com/forms/d/e/1FAIpQLScQvIbKcue22bb9kdrvPab6bSFhPEZ070fIjHYmLiLtn8S01A/viewform?usp=sf_link' text="Submit Coin" />
          <CustomLink color='neutral' href='https://docs.google.com/forms/d/e/1FAIpQLScQvIbKcue22bb9kdrvPab6bSFhPEZ070fIjHYmLiLtn8S01A/viewform?usp=sf_link' text='Update Request' />
          <CustomLink color='neutral' href='https://t.me/+vgG0LHjya8JhN2Nh' text="Contact Us" />
          <CustomLink color='neutral' href='/services/' text="Services" />
          <CustomLink color='neutral' href='/services/' text="Advertise" />
        </div> */}
      </div>
      <Typography style={{ color: 'white' }}>WenScope, powered By WenWifHat.</Typography>
      <div class='flex gap-2'>
        <Button className="bg-[#feb200]" isIconOnly variant='solid' color='danger' radius="full" sx={{ height: '42px', width: '42px', padding: 0, borderRadius: '50px' }} onClick={() => window.open('https://x.com/WenScope')}>
          <img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644701/twitter-white_n2t2hj.svg' />
        </Button>
        <Button className="bg-[#feb200]" isIconOnly variant='solid' color='danger' radius="full" sx={{ height: '42px', width: '42px', padding: 0, borderRadius: '50px' }} onClick={() => window.open('https://t.me/wenscopeofficial')}>
          <img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644484/telegram_zzxn1u.svg' />
        </Button>
      </div>
      {/* <Divider />
      <Typography style={{ opacity: 0.5, color: 'black' }}>© 2024 bixiscope</Typography> */}
    </div>;
  }

  export default Footer;