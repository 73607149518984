import { Divider, Link, Typography } from "@mui/joy";
import useWindowDimensions from "../modules/useWindowDimensions";
import { useEffect, useState } from "react";
import { Avatar, Button } from "@nextui-org/react";

export default function Notices() {

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    
    useEffect(() => {
        setWidth(windowWidth);
        setHeight(windowHeight);
    }, [windowWidth, windowHeight]);


    return <div class='flex container my-10 flex-col gap-10 px-4'>
        <div class='flex items-center justify-around bg-white px-4 py-10' style={{background: '#101113' || '#1e2635', border: '2px #21232e solid', borderRadius: '10px'}}>
            <div class='flex flex-col items-center gap-4'>
                <Typography className='!text-white' textAlign="center" sx={{textDecoration: 'underline'}} level={'h'+(width < 1024 ? 3 : 2)} fontWeight={600} letterSpacing={1}>Your Favorite Coin Missing?</Typography>
                <div class='flex items-center flex-col'>
                    <Typography className='!text-white' level='body-md' textAlign="center" style={{opacity: 0.5}}>Can't find your coin? List your favorite coin now!</Typography>
                    <Typography className='!text-white' level='body-md' textAlign="center" style={{opacity: 0.5}}>Get your community to vote for your coin and gain exposure.</Typography>
                </div>
                <Button className='bg-[#feb200] text-black' color='danger' radius="sm" sx={{marginTop: 3}} size='lg' onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSdUCYBun7_RCLUqCzwtFe-abIQVAV-HDWXMdsYyVIISqgX8IQ/viewform?usp=sf_link')}>Submit Coin</Button>
            </div>
            {/* <Divider orientation={width > 1024 ? "vertical" : "horizontal"} sx={{margin: 2}} />
            <div class='flex flex-col items-center'>
            <Typography textAlign="center" level={'h'+(width < 1024 ? 4 : 2)} fontWeight={600} letterSpacing={1}>Advertise your Project?</Typography>
            <div class='flex items-center flex-col'>
            <Typography level='body-md' textAlign="center" style={{opacity: 0.5}}>Click the button below!</Typography>
            <Typography level='body-md' textAlign="center" style={{opacity: 0.5}}>Just submit.</Typography>
            </div>
            <Button color='primary' sx={{marginTop: 3}} size='lg' onClick={() => window.open('https://t.me/cadokpro')}>Contact Us</Button>
        </div> */}
        </div>
        <div className="flex flex-col items-center gap-6">
            <Typography textAlign="center" level={'h'+(width < 1024 ? 3 : 2)} fontWeight={600} letterSpacing={1} className='!text-white'>Partners</Typography>
            <div className="flex gap-4 flex-wrap justify-center">
                <Link href='https://t.me/BucksFirehouse'>
                    <Avatar className='h-24 w-24' src="https://res.cloudinary.com/dvwrpep92/image/upload/v1705569550/8bf75498-d304-4f55-9503-14e16e09c63a_lczft9.jpg" size='lg' />
                </Link>
                <Link href='https://t.me/skeletonservices/33'>
                    <Avatar className='h-24 w-24' src="https://res.cloudinary.com/dvwrpep92/image/upload/v1705569549/64396a86-0bcc-49f3-b144-3fba87b60fb7_ygxjp2.jpg" size='lg' />
                </Link>
            </div>
        </div>
        {/* <div>
            <Typography level={'h'+(width < 1024 ? 4 : 1)} fontWeight={600}>Find the best new cryptocurrency projects</Typography>
            <div class='flex flex-col gap-4'>
                <Typography level='body-md' sx={{color: '#6d7783'}}>Did ever you wonder where people find the best new cryptocurrency projects, coins and tokens like Doge and Shiba Inu? They use websites like <Link color='primary' href='/'>cadok.pro</Link>. Cryptocurrency projects are listed here before CoinMarketCap, CoinGecko and major exchanges. Find the best crypto moonshots on our website.</Typography>
                <Typography level='body-md' sx={{color: '#6d7783'}}>However: before investing always do your own research (DYOR)! Listing on <Link color='primary' href='/'>cadok.pro</Link> does NOT mean we endorse the project, they could be scams. Be careful with your investments.</Typography>
            </div>
        </div>
        <div>
            <Typography level={'h'+(width < 1024 ? 4 : 1)} fontWeight={600}>How does Cadok work?</Typography>
            <div class='flex flex-col gap-4'>
                <Typography level='body-md' sx={{color: '#6d7783'}}>New cryptocurrency projects can be listed <Link href='https://docs.google.com/forms/d/e/1FAIpQLScQvIbKcue22bb9kdrvPab6bSFhPEZ070fIjHYmLiLtn8S01A/viewform' target="_blank">Applying Here.</Link>. Once applied, they instantly become visible on the New Listings Page. New listings require 500 votes to be officially listed in our top list. After that, anyone can see and vote for the project.</Typography>
                <Typography level='body-md' sx={{color: '#6d7783'}}>Get your community to vote on your project, because votes matter! Our ranking is simple: the highest votes is #1 on our website. The project will get exposure with all our visitors!</Typography>
            </div>
        </div> */}
    </div>
}