import { AutoComplete, Autocomplete, AutocompleteOption, Image, Input, ListItemContent, ListItemDecorator } from "@mui/joy";
import { useState, useEffect } from "react";
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
// import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import qs from 'qs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { ConfigProvider, Table, message } from 'antd';

import ReCAPTCHA from "react-google-recaptcha";
import VoteModal from "./Vote";
import useWindowDimensions from "../modules/useWindowDimensions";
import { Avatar, Button, Select, SelectItem } from "@nextui-org/react";

const THEME = {
  "token": {
    "colorPrimary": "#feb200",
    "colorInfo": "black",
    "colorBgBase": "#101113",
    "wireframe": false,
    "colorTextBase": "white"
  },
  "components": {
    "Table": {
      "colorBorderSecondary": "#21232e",
      "colorPrimary": "#feb200",
    }
  }
}

const getRandomuserParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const Chains = [
  {
    name: 'ETH',
    icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1704569810/eth_nfk0da.svg'
  },
  {
    name: 'BSC',
    icon: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=026'
  },
  {
    name: 'ARB',
    icon: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=026'
  },
  {
    name: 'SOL',
    icon: 'https://cryptologos.cc/logos/solana-sol-logo.svg?v=029'
  },
  {
    name: 'APT',
    icon: 'https://cryptologos.cc/logos/aptos-apt-logo.svg?v=029'
  },
  {
      name: 'OP',
      icon: 'https://cryptologos.cc/logos/optimism-ethereum-op-logo.svg?v=029'
  },
  {
      name: 'AVAX',
      icon: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=029'
  }
]

const Platforms = [
  {
    name: 'CMC',
    icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084286/cmc_mnw1kn.png'
  },
  {
    name: 'CG',
    icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084285/cg_xgrfxz.png'
  },
  {
    name: 'CMC_CG',
    icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084283/cmc-cg_aov988.png'
  }
]

function LeaderBoard() {

  const columns = [
    {
      title: '#',
      dataIndex: 'data',
      render: ({ index }) => <Typography className='!text-white'  level='title-md'>{index}</Typography>
    },
    {
      title: 'Coin',
      dataIndex: 'data',
      render: ({ icon }) => <Avatar src={icon} size="lg" style={{ borderRadius: '6px', background: 'transparent' }} />,
      fixed: 'left'
    },
    {
      title: 'Name',
      dataIndex: 'data',
      render: (dd) => <Box component="div" onClick={() => window.open('/coins/' + (dd.baseToken?.address || dd.token), '_self')} sx={{ ":hover": { cursor: 'pointer' } }}>
        <Typography className='!text-white'  level='title-md'>{dd.baseToken?.name || dd.name}</Typography>
        <Typography  className='!text-white' level='body-sm'>{dd.baseToken?.symbol}</Typography>
      </Box>
    },
    {
      title: 'CMC/CG',
      dataIndex: 'data',
      render: ({ platform }) => {
        if (!platform) {
          return <div></div>
        }
        return <div class='flex'>
          <Avatar src={Platforms.filter(r => r.name == platform)[0]?.icon} size='sm' style={{ height: '20px', width: '20px', marginRight: '10px' }} />
          <Typography className='!text-white'  level='title-sm'>{platform.replace("_", " & ")}</Typography>
        </div>
      }
    },
    {
      title: 'Chain',
      dataIndex: 'data',
      render: ({ chain }) => <div class='flex'>
        <Avatar src={Chains.filter(r => r.name == chain)[0].icon} size='sm' style={{ height: '20px', width: '20px', marginRight: '10px' }} />
        <Typography  className='!text-white' level='title-sm'>{chain}</Typography>
      </div>
    },
    {
      title: 'Market Cap',
      dataIndex: 'data',
      render: ({ fdv }) => <div class='flex'>
        <Typography  className='!text-white' level='title-sm'>$ {fdv?.toLocaleString('en-US')}</Typography>
      </div>
    },
    {
      title: 'Price',
      dataIndex: 'data',
      render: ({ priceUsd }) => <div class='flex'>
        <Typography  className='!text-white' level='title-sm'>$ {priceUsd}</Typography>
      </div>
    },
    {
      title: 'Change 24h',
      dataIndex: 'data',
      render: ({ priceChange }) => {
        if (!priceChange) {
          priceChange = { h24: 0 }
        }
        return <div class='flex'>
          <Typography className='!text-white'  level='title-sm' style={{ color: (priceChange.h24 > 0) ? '#00ab70' : '#d8412e' }} startDecorator={(priceChange.h24 > 0) ? <ArrowDropUpIcon style={{ color: '#00ab70' }} /> : <ArrowDropDownIcon style={{ color: '#d8412e' }} />}>{priceChange.h24}%</Typography>
        </div>
      }
    },
    {
      title: 'Votes',
      dataIndex: 'data',
      render: ({ votes }) => <div class='flex'>
        <Typography className='!text-white' level='title-sm'>{votes}</Typography>
      </div>,
      sorter: true
    },
    {
      title: '',
      dataIndex: 'data',
      render: ({ token }) => {
        return <Button className="bg-[#feb200] text-black" color="danger" radius="sm" isDisabled={localStorage.getItem(token) ? true : false} onClick={() => { setOpen(true); setCurrentTokenForVote(token) }}>Vote</Button>
      },
    }
  ];


  const [open, setOpen] = useState(false);
  const [currentTokenForVote, setCurrentTokenForVote] = useState();
  const [data, setData] = useState();
  const [safeChain, setSafeChain] = useState('All');
  const [safePlatform, setSafePlatform] = useState('All');
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [fetches, setFetches] = useState(0)

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  useEffect(() => {
    setWidth(windowWidth);
    setHeight(windowHeight);
  }, [windowWidth, windowHeight]);


  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 15,
    },
  });
  const fetchData = (chain, platform = '') => {
    setLoading(true);
    fetch(`https://bixiscope-api.vercel.app/api/get_filtered_data?${qs.stringify(getRandomuserParams(tableParams))}&chain=` + (chain || safeChain) + '&platform=' + (platform || safePlatform))
      .then((res) => res.json())
      .then((res) => {

        const d = {...res}
        setData(d.items);
        const o = fetches + 1
        setFetches(o)
        setLoading(false);

        // let paginated_data = res.items
        // let order = res.order
        // let field = res.field
        // // Convert 'paginated_data' to an array of token strings
        // const tokenStrings = paginated_data.map(x => x.token.toLowerCase());

        // if (paginated_data.length == 0) {
        //   setData([])
        //   setLoading(false)
        //   return
        // }

        // // Fetch data from the API
        // fetch('https://api.dexscreener.com/latest/dex/tokens/' + tokenStrings.join(','))
        //   .then(response => response.json())
        //   .then(data => {
        //     const nopairs = [];
        //     const nopairsnames = [];

        //     data.pairs.forEach(i => {
        //       const baseTokenAddress = i.baseToken.address.toLowerCase();
        //       if (tokenStrings.includes(baseTokenAddress)) {
        //         if (!nopairsnames.includes(i.baseToken.name)) {
        //           nopairsnames.push(i.baseToken.name);

        //           // Find the corresponding 'paginated_data' item
        //           const matchingItem = paginated_data.find(o => o.token.toLowerCase() === baseTokenAddress);

        //           if (matchingItem) {
        //             nopairs.push({
        //               ...i,
        //               ...matchingItem
        //             });
        //           }
        //         }
        //       }
        //     });

        //     // Sort the 'nopairs' array
        //     const isDescending = (order === 'descend');
        //     nopairs.sort((a, b) => {
        //       const aValue = a[field] || 0;
        //       const bValue = b[field] || 0;
        //       return isDescending ? bValue - aValue : aValue - bValue;
        //     });

        //     // 'nopairs' now contains the sorted and filtered data
        //     console.log(nopairs);

        // setData(nopairs);
        // setLoading(false);
        //   })
        //   .catch(error => {
        //     console.error('An error occurred:', error);
        //   });
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.totalCount,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      }).catch(r => {
        console.log('FAILED LEADERBOARD')
      })
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <div class='w-full flex flex-col items-start gap-4 my-[30px]'
    // style={width < 520 ? { paddingInline: '2rem' } : {}}
    >
      <ConfigProvider theme={{
        components: {
          Message: {
            zIndexPopup: 9999
          }
        }
      }}>
        {contextHolder}
      </ConfigProvider>
      <VoteModal open={open} setOpen={setOpen} currentTokenForVote={currentTokenForVote} messageApi={messageApi} />
      <Typography level='h2' className='!text-white'>Browse Coins</Typography>
      <div class='flex gap-4 flex-wrap w-full flex-row'>
          <Select className="dark" classNames={{base: 'w-fit'}} style={{width: '130px'}} size='md' onSelectionChange={(e) => {
              setSafeChain(Array.from(e)[0])
              fetchData(Array.from(e)[0])
            }} labelPlacement="outside" defaultSelectedKeys={['All']} label='Chain' variant="faded">
            <SelectItem key="All" value='All'>
                All
            </SelectItem>
            {
              Chains.map(r => (
                <SelectItem startContent={<Avatar src={r.icon} size='sm' style={{ height: '20px', width: '20px', marginRight: '10px' }} />} key={r.name} value={r.name}>{r.name}</SelectItem>
                ))
              }
          </Select>
          <Select className="dark" classNames={{base: 'w-fit'}} style={{width: '180px'}} size='md' onSelectionChange={(e) => {
            setSafePlatform(Array.from(e)[0])
            fetchData(safeChain, Array.from(e)[0].replace(" & ", '_'))
          }} labelPlacement="outside" defaultSelectedKeys={['All']} label='Platform' variant="faded">
            <SelectItem key="All" value='All'>
                All
            </SelectItem>
            {
              Platforms.map(r => (
                <SelectItem startContent={<Avatar src={r.icon} size='sm' style={{ height: '20px', width: '20px', marginRight: '10px' }} />} key={r.name} value={r.name}>{r.name.replace('_' , ' & ')}</SelectItem>
              ))
            }
          </Select>

        {/* <div>
          <FormLabel sx={{ marginBottom: '10px', opacity: 0.6, fontSize: '14px', fontWeight: 300 }}>
            Platform
          </FormLabel>
          <Select
            defaultValue="All"
            color='black'
            onChange={(e) => {
              setSafePlatform(e.target.innerText.replace(" & ", '_'))
              fetchData(safeChain, e.target.innerText.replace(" & ", '_'))
            }}
            slotProps={{
              listbox: {
                sx: (theme) => ({
                  background: '#fcb900',
                  border: 'none',
                  // boxShadow: 'none'
                })
              }
            }}
            sx={{width: '140px'}}
            >
            <Option value="All">All</Option>
            {
              Platforms.map(r => <Option value={r.name}>
                <Avatar src={r.icon} size='sm' style={{ height: '20px', width: '20px', marginRight: '10px' }} />
                <Typography level='title-md'>{r.name.replace('_' , ' & ')}</Typography>
              </Option>)
            }
          </Select>
        </div> */}
      </div>
      <ConfigProvider theme={THEME}>

        <Table
          columns={columns}
          rowKey={(record) => record.data.token+fetches}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
          style={{ width: '100%', borderRadius: '10px', border: '1px #21232e solid', overflow: 'hidden', background: '#101113' }}
        />
      </ConfigProvider>
    </div>
  );
}

export default LeaderBoard;