import { Box, Image, ListItemContent, ListItemDecorator, TabList, Tabs, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
// import SearchIcon from '@mui/icons-material/Search';
import useWindowDimensions from "../modules/useWindowDimensions";
import MenuIconSvg from '../assets/menu.svg'
import SearchBarModal from "./SearchBar";

import SearchIconSvg from '../assets/search.svg'
import SearchIconWhiteSvg from '../assets/searchwhite.svg'
import MenuDrawer from "./MenuDrawer";
import Tab, { tabClasses } from '@mui/joy/Tab';
import { Dropdown } from "antd";
import { Autocomplete, Avatar, Button, Input, Link, useDisclosure } from "@nextui-org/react";

function SearchIcon() {
  return <img src={SearchIconSvg} style={{opacity: 0.2}} />
}

function MenuIcon() {
  return <img src={MenuIconSvg} />
}


function TopMenu({disableBottomTabs}) {

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch('https://bixiscope-api.vercel.app/api/get_hots').then(r => r.json()).then(r => {
      setData(r)
      setLoading(false)
    })
  }, [])

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    setWidth(windowWidth);
    setHeight(windowHeight);
  }, [windowWidth, windowHeight]);

  // const [q, setQ] = useState('')

  function SearchBarShowcase() {
    return <div className='hover:cursor-pointer w-[384px] relative' onClick={onOpen}>
        {/* <Autocomplete placeholder="Search coins"  startContent={<SearchIcon />} className="w-[384px] pointer-events-none p-0" size='sm' variant='bordered' radius="sm" classNames={{
            'base':
              "",
            input: "text-white",
          }}>

      </Autocomplete> */}
    <Input
          startContent={
            <Button
              isIconOnly
              className='!min-w-fit !w-fit mr-2 bg-transparent'
              radius='full'>
              <img src='/assets/search.svg' />
            </Button>
          }
          placeholder='Search coins'
          radius='sm'
          size='md'
          className='w-[100%] pointer-events-none'
          classNames={{
            inputWrapper:
              "bg-[#101113] bg-[#27272a] border-[0px] bg-transparent border-[#21232e] border-[2px] hover:!border-[0px] color-white",
            input: "text-white",
          }}
          variant='faded'
        />
      </div>
  }

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  return (
    <div class='w-full bg-[black] border-b-[#21232e] border-b-[1.5px] flex flex-col items-center mb-10'>
      {
        !loading ? 
      <div class='h-full w-full bg-black flex justify-center border-b-[#21232e] border-b-[1.5px]'>
        <div class='container w-full flex justify-start items-center py-1 gap-10 overflow-x-scroll'>
            <div class='flex gap-2 pl-3'>
              <Typography fontWeight={600}  sx={{whiteSpace: 'nowrap', color: '#feb200'}} level='body-xs'>BNB Price:</Typography>
              <Typography  fontWeight={600} level='body-xs' sx={{whiteSpace: 'nowrap', color: '#feb200'}}>${data.bnb}</Typography>
            </div>
            <div class='flex gap-2 pl-3'>
              <Typography fontWeight={600}  sx={{whiteSpace: 'nowrap', color: '#feb200'}} level='body-xs'>ETH Price:</Typography>
              <Typography  fontWeight={600} level='body-xs' sx={{whiteSpace: 'nowrap', color: '#feb200'}}>${data.eth}</Typography>
            </div>
            <div class='flex gap-2'>
              <Typography  fontWeight={600} sx={{whiteSpace: 'nowrap', color: '#feb200'}} level='body-xs'>Projects Listed:</Typography>
              <Typography fontWeight={600}  level='body-xs' sx={{whiteSpace: 'nowrap', color: '#feb200'}}>{data.listed}</Typography>
            </div>
            <div class='flex gap-2 pr-3'>
              <Typography  fontWeight={600} sx={{whiteSpace: 'nowrap', color: '#feb200'}} level='body-xs'>Total Votes:</Typography>
              <Typography  fontWeight={600} level='body-xs' sx={{whiteSpace: 'nowrap', color: '#feb200'}}>{data.votes}</Typography>
            </div>
        </div>
      </div>
      : ''}
      <div class='container h-20 w-full flex justify-between items-center px-6'>
        <SearchBarModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        <MenuDrawer state={state} setState={setState} />
        <Link href='/'><div className="flex gap-3 items-center"><img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1717171261/18e774ff-aadf-4741-8b8c-d71cc7cb8c6c.png' className="h-10 hover:cursor-pointer rounded-full hover:opacity-75 transition-opacity" /><p className='text-2xl !text-white'>WenScope</p></div></Link>
        {/* <div class='h-full flex items-center gap-4'>
          <img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1704551769/bixiscope_c6topq.png' onClick={() => window.open('/', '_self')} class='hover:cursor-pointer h-10' />
        </div> */}
        {
          width > 770 ? <SearchBarShowcase /> : ''
        }
        <div class={'flex gap-' + (width < 770 ? '2' : '6')}>
          {
            (width > 1280) ?
            <div class='flex items-center'>
              <Dropdown menu={{items: [
                {key: '1', label: <Link target='_blank' href='https://t.me/WENWIFHATCHAT'>Banner Ads</Link>},
                {key: '2', label: <Link target='_blank' href='https://t.me/WENWIFHATCHAT'>Trending</Link>},
                {key: '3', label: <Link target='_blank' href='https://t.me/WENWIFHATCHAT'>KYC</Link>},
                {key: '3', label: <Link target='_blank' href='https://t.me/skeletonservices/33' startDecorator={<Avatar className='h-5 w-5' src='https://res.cloudinary.com/dvwrpep92/image/upload/v1705569549/64396a86-0bcc-49f3-b144-3fba87b60fb7_ygxjp2.jpg' size='sm' />}>Audit</Link>},
              ]}} placement="bottomRight" arrow>
                <Button variant="plain" sx={{color: 'white', ":hover": {color: 'white', background: 'transparent'}}}>
                  <Typography style={{color: 'white'}} level='body-md'>Services</Typography>
                </Button>
              </Dropdown>
              <Dropdown menu={{items: [
                {key: '1', label: <Link target='_blank' href='https://t.me/WENWIFHATCHAT'>Update Coin</Link>},
                {key: '2', label: <Link target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLSdUCYBun7_RCLUqCzwtFe-abIQVAV-HDWXMdsYyVIISqgX8IQ/viewform?usp=sf_link'>Submit Coin</Link>},
                {key: '3', label: <Link target='_blank' href='https://t.me/WENWIFHATCHAT'>Contact Us</Link>}
              ]}} placement="bottomRight" arrow>
                <Button variant="plain" sx={{color: 'white', ":hover": {color: 'white', background: 'transparent'}}}>
                  <Typography style={{color: 'white'}} level='body-md'>Listing</Typography>
                </Button>
              </Dropdown>
            </div>
         : '' }
        {/* {
            width > 1024 ?
              <div class='flex gap-2'>
                <Button variant='solid' color='primary' sx={{ height: '42px', width: '42px', borderRadius: '10px', padding: 0 }} isIconOnly size='lg' onClick={() => window.open('https://twitter.com/catdog_coin')}>
                  <img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644701/twitter-white_n2t2hj.svg' />
                </Button>
                <Button variant='solid' color='primary' sx={{ height: '42px', width: '42px', borderRadius: '10px', padding: 0 }} isIconOnly size='lg' onClick={() => window.open('https://t.me/+vgG0LHjya8JhN2Nh')}>
                  <img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644484/telegram_zzxn1u.svg' />
                </Button>
              </div> : ''
          } */}
          {
            width > 770 ?
              <Button color='default'
              className="bg-[#feb200]"
                size="lg" radius='sm' style={{ display: width < 700 ? 'none' : 'block', fontWeight: 600 }} onClick={() => window.open('https://t.me/skeletonservices/33')} radius="sm">SKELETON BOT</Button> : ''
          }
          {
            width <= 770 ? <Button isIconOnly variant="outlined" style={{
              padding: 0
            }} onClick={onOpen}>
              <img src={SearchIconWhiteSvg} style={{ height: '28px' }} />
            </Button> : ''
          }
          {
            (width < 1280 && !disableBottomTabs) ? 
          <Button isIconOnly variant="outlined" style={{
            padding: 0
          }} onClick={() => {
            setState({ ...state, right: true })
          }}><MenuIcon /></Button> : ''}
        </div>
      </div>
      {/* {
        (width > 1280 && !disableBottomTabs) ?
      <div class='container px-80'>
        <UseLess />
      </div>
      : '' } */}
    </div>
  );
}

export default TopMenu;