import { AutoComplete, Autocomplete, AutocompleteOption, Avatar, Button, Image, Input, ListItemContent, ListItemDecorator } from "@mui/joy";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { ConfigProvider, Spin } from "antd";
import useWindowDimensions from "../modules/useWindowDimensions";
import { Spinner } from "@nextui-org/react";

function Shots() {

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    useEffect(() => {
    setWidth(windowWidth);
    setHeight(windowHeight);
    }, [windowWidth, windowHeight]);

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetch('https://bixiscope-api.vercel.app/api/get_shots').then(r => r.json()).then(r => {
            setData(r)
            setLoading(false)
        })
    }, [])

    function Shot({title, name, getData, getValue}) {


        if (loading) {
            return <Card sx={{width: width < 920 ? '100%' : '50%', display: 'flex', alignItems: 'flex-start', background: '#101113' || '#1e2635', border: '2px #21232e solid', borderRadius: '10px'}}>
                    <ConfigProvider theme={{"token": {"colorPrimary": "#01a970","colorInfo": "#01a970","colorBgBase": "#1e2635","wireframe": false,"colorTextBase": "#ffffff"}}}>
                        <Spinner color="danger" />
                    </ConfigProvider>
                </Card>
        }

        return <Card sx={{width: width < 920 ? '100%' : '50%', display: 'flex', alignItems: 'flex-start', background: '#101113' || '#1e2635', border: '2px #21232e solid', borderRadius: '10px'}}>
        <Typography level='title-lg' className='!text-white'>{title}</Typography>
        <Card sx={{display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row', border: '0px', padding: 1, paddingInline: 2, background: '#feb200'}}>
            <Typography level='body-sm' sx={{opacity: 1, color: 'black'}}>Projects</Typography>
            <Typography level='body-sm' sx={{opacity: 1, color: 'black'}}>{name}</Typography>
        </Card>
        {data[getData].map((r, i) => <Card sx={{display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row', border: '0px', padding: 0, paddingInline: 2, background: 0}}>
            <div class='flex gap-4 items-center'>
            <Avatar style={{height: '30px', width: '30px', borderRadius: '2px', background: 'transparent'}} src={r.icon}/>
            <Typography style={{color: 'white' || '#ffba00'}} fontWeight={600} onClick={() => window.open('/coins/'+r.token, '_self')} sx={{":hover": {cursor: 'pointer', textDecoration: 'underline'}}}>{r.name}</Typography>
            </div>
            <Typography style={{color: 'white'}} fontWeight={600}>{getValue(r, i)}</Typography>
        </Card>)}
    </Card>
    }

  return (
    <div class='w-full flex flex-row gap-10' style={(width < 920 ? {flexDirection: 'column'} : {})}>
        <Shot title="Trending Coins 🔥" name="Rank" getData="promoted" getValue={(r, i) => '#'+(i+1)} />
        <Shot title="Recently Added ⏳" name="Submitted" getData="recent" getValue={(r) => r.added} />
        <Shot  title="Top Voted 🚀" name="Votes" getData="top_voted" getValue={(r) => r.votes} />
        {/* <Shot title="Highlighted Coins ⚡️" name="" getData="promoted" getValue={(r) => "Highlighted"} /> */}
    </div>
  );
}

export default Shots;