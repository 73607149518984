import { useState, useEffect, memo } from 'react';
import Sheet from '@mui/joy/Sheet';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Autocomplete, AutocompleteItem, User, Link } from "@nextui-org/react";

import SearchIconSvg from '../assets/search.svg'
import { Typography } from '@mui/joy';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


export default function SearchBarModal({ isOpen, onClose }) {

    function SearchIcon() {
        return <img src={SearchIconSvg} style={{ opacity: 0.2 }} />
    }

    const [searchResults, setSearchResults] = useState([])
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        if (searchValue.length < 2) return;
        fetch('https://bixiscope-api.vercel.app/api/search?q=' + (searchValue)).then(r => r.json()).then(r => {
            console.log(r)
            setSearchResults(r)
        });
    }, [searchValue])

    // function fetchData(newVal) {
    //     // setIsLoading(true);
    // fetch('https://bixiscope-api.vercel.app/api/search?q=' + (newVal || '')).then(r => r.json()).then(r => {
    //     setData(r)
    //     // setIsLoading(false);
    // });
    // }

    // useEffect(() => {
    //     fetchData()
    // }, [])


    // useEffect(() => {
    //     if (inputValue.length > 1) {
    //         fetchData(inputValue);
    //     }
    // }, []);



    // function SearchBarInput() {
    //     return       <Autocomplete
    //     color='success'
    //     startDecorator={<SearchIcon />}
    // endDecorator={<CloseRoundedIcon sx={{background: '#ffba00', color: 'white', height: '100%', width: '42px', padding: 1, borderRadius: '10px', zIndex: 5, ":hover": {
    //     cursor: 'pointer'
    // }, position: 'absolute', right: 0 }} onClick={() => setOpen(false)}/>}
    //     placeholder="Search coins"
    //     size='lg'
    //     options={data}
    //     open
    //     class='container'
    //     isOptionEqualToValue={(option, value) => option.name.toLowerCase().includes(value.toLowerCase())}
    //     sx={{
    //   width: '50%',
    //   minWidth: '300px',
    //       background: '#1F2634',
    //       boxShadow: 'none',
    //       borderRadius: '10px',
    //       borderColor: '#ffba00',
    //       paddingRight: 0,
    //       "& input::placeholder": {
    //         fontWeight: 300,
    //         opacity: 0.2
    //       },
    //     }}
    //     getOptionLabel={(option) => {
    //       return option.name || inputValue
    //     }}
    //     // value={inputValue}
    //     onInputChange={(e, newVal) => {
    //       // fetchData(newVal)
    //       setInputValue(newVal)
    //     }}        
    //     // disabled={isLoading}
    //     renderOption={(props, option) => (
    //   <Box sx={{display: 'flex', padding: 1, borderRadius: '10px', margin: 1, background: '#0e172b', border: '1px #3c4456 solid', ":hover": {
    //     cursor: 'pointer'
    //   }}} key={option.token} onClick={() => window.open('/coins/' + option.token, '_self')}>
    //     <ListItemDecorator sx={{ marginRight: '10px' }}>
    //       <img
    //         loading="lazy"
    //         width="40"
    //         src={option.icon}
    //         alt=""
    //       />
    //     </ListItemDecorator>
    //     <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    //       <ListItemContent>
    //         <Typography style={{ color: 'white' }} level="title-sm">{option.name}</Typography>
    //         <Typography style={{ color: 'white', opacity: 0.6 }} level="body-sm">{option.token.slice(0, 4)}...{option.token.slice(-4)}</Typography>
    //       </ListItemContent>
    //       <ListItemContent>
    //       </ListItemContent>
    //     </div>
    //   </Box>
    //     )}
    // slotProps={{
    //   listbox: {
    //     sx: (theme) => ({
    //       background: 'transparent',
    //       border: 'none',
    //       boxShadow: 'none'
    //     })
    //   }
    // }}
    //   />
    // }

    // useEffect(() => {
    //     fetch('https://bixiscope-api.vercel.app/api/search?q=' + inputValue).then(r => r.json()).then((data) => setData(data));
    //   }, [inputValue]);

    // const SearchBarInput = memo(() => {

    //     return (
    //         <div class='w-full container flex items-center flex-col gap-4 h-full'>
    //             <Input color='success' placeholder='Search coins' size='lg'  sx={{
    //                 background: 'white' || '#1F2634',
    //                 boxShadow: 'none',
    //                 borderRadius: '10px',
    //                 width: '50%',
    //                 minWidth: '300px',
    //                 borderColor: '#a91a1f',
    //                 color: 'black',
    //                 ":focus": {
    //                     borderColor: '#f4b55b'
    //                 },
    //                 "& input::placeholder": {
    //                     fontWeight: 300,
    //                     opacity: 0.2,
    //                     color: 'black'
    //                 },
    //                 }}
    //                 startDecorator={<SearchIcon />}
    //                 endDecorator={<CloseRoundedIcon onClick={() => setOpen(false)} sx={{background: '#f4b55b'||'#ffba00', color: 'white', height: '100%', width: '42px', padding: 1, borderRadius: '10px', zIndex: 5, ":hover": {
    //                     cursor: 'pointer'
    //                 }, position: 'absolute', right: 0 }} />}
    //                 value={inputValue}
    //                 autoFocus
    //                 onChange={(e, newVal) => {
    //                     setInputValue(e.target.value)
    //                     // fetchData(e.target.value)
    //                 }}
    //                 // onBlur={() => {
    //                 // }}
    //             />
    //             <div class='flex flex-col gap-2 w-full items-center h-full overflow-y-scroll hide-scroll'>
    //                 {
    //                     data.map(option => {
    //                         console.log(option)
    //                         return <Box sx={{display: 'flex', padding: 1, borderRadius: '10px', margin: 1, background: '#f4b55b'|| '#0e172b', border: '1px #fef5d0 solid', ":hover": {
    //                             cursor: 'pointer'
    //                           },
    //                           width: '50%',
    //                           minWidth: '300px',
    //                           }} key={option.token} onClick={() => window.open('/coins/' + option.token, '_self')}>
    //                             <ListItemDecorator sx={{ marginRight: '10px' }}>
    //                               <Avatar
    //                                 loading="lazy"
    //                                 sx={{width: '40px', height: '40px', background: 'transparent', borderRadius: 0}}
    //                                 width="40"
    //                                 src={option.icon}
    //                                 alt=""
    //                               />
    //                             </ListItemDecorator>
    //                             <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    //                               <ListItemContent>
    //                                 <Typography style={{ color: 'black' }} fontWeight={600} level="title-sm">{option.name}</Typography>
    //                                 <Typography style={{ color: 'black', opacity: 0.6 }} level="body-sm">{option.token.slice(0, 4)}...{option.token.slice(-4)}</Typography>
    //                               </ListItemContent>
    //                               <ListItemContent>
    //                               </ListItemContent>
    //                             </div>
    //                           </Box>
    //                     })
    //                 }
    //             </div>
    //         </div>
    //     )
    // })


    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onClose={onClose}
            backdrop="blur"
            hideCloseButton={true}
            isDismissable={true}
            placement='top'
        >
            <ModalContent className='bg-transparent shadow-none'>
                {(onClose) => (
                    <ModalBody className='flex items-center h-full py-10 bg-transparent shadow-none flex-row gap-4'>

                        <Autocomplete clearIcon={false} placeholder="Search coins" startContent={<SearchIcon />} className="w-full" size='md' radius="lg" onValueChange={setSearchValue} items={searchResults} variant={'solid'} onSelectionChange={(e) => window.open('/coins/'+e, "_self")}>
                            {
                                (item) => <AutocompleteItem key={item.token} value={item.token}><Link href={'/coins/' + item.token} target="_blank"><User name={<Typography level='body-sm' fontWeight={700} sx={{ color: 'black' }}>{item.name}</Typography>} description={<Typography fontWeight={600} level='body-xs'>{item.token.slice(0, 4) + '....' + item.token.slice(-4)}</Typography>} avatarProps={{
                                    src: item.icon,
                                    isBordered: false,
                                    size: 'md',
                                    className: 'bg-transparent'
                                }} /></Link></AutocompleteItem>
                            }
                        </Autocomplete>
                        <Button isIconOnly onPress={onClose} size='lg'><CloseRoundedIcon /></Button>
                    </ModalBody>
                )}
            </ModalContent>
        </Modal>
    );
}
