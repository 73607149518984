import { Button, DialogContent, DialogTitle, Modal, ModalDialog } from "@mui/joy";
import { Code } from "@nextui-org/react";
import { message } from "antd";
import ReCAPTCHA from "react-google-recaptcha";


function VoteModal({open, setOpen, currentTokenForVote, messageApi}) {
    
    return (
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog sx={{padding: 1}}>
            <Code color="danger">Verify yourself to vote</Code>
            <ReCAPTCHA
        sitekey="6LeLNVIoAAAAAIegYQLYa8RDn8rtoRNg7JJYQxDM"
        onChange={(token) => {
            fetch('https://bixiscope-api.vercel.app/api/vote', {
                method: 'POST',
                body: JSON.stringify({token: currentTokenForVote, captcha: token}),
                headers: {
                    'content-type': 'application/json'
                }
            }).then(r => r.json()).then(r => {
                if (r.success) {
                    messageApi.success('Voted successfully', 2)
                    localStorage.setItem(currentTokenForVote, 'true')
                    setTimeout(() => {
                        setOpen(false)
                        window.location.reload()
                    }, 1000);
                }
            })
        }}
      />
          </ModalDialog>
        </Modal>
    );
}

export default VoteModal